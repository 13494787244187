<template>
	<div v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="pa-3">
            <v-card-title>Formulaires :</v-card-title>
            <v-card-text>
                <FormTab :profile="profile" :user="user" :notifications="notifications" :currentRoom="currentRoom"></FormTab>
            </v-card-text>
        </v-card>
	</div>
</template>

<script>
	import FormTab from "../components/Form/FormTab.vue"

	export default {
		components: {
            FormTab,
		},
		name: "Forms",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
		setup() {
			return {
			}
		},
		data() {
			return {
				tab: 0,
			}
		},
	}
</script>
