<template>
    <div class="d-flex align-center flex-wrap">
        <div class="d-flex align-center justify-center ml-1">
            <b v-text="'Q' + (index+1) + ' :'" style="font-size:20px"></b>
        </div>

        <v-col style="min-width:250px" class="py-0">
            <v-text-field placeholder="Titre de la question" v-model="question.text" />
        </v-col>
        
        <div class="d-flex align-center">
            <div class="d-flex">
                <b class="mr-2">Type : </b>
                <div v-text="txtType"></div> 
            </div>
            <v-btn color="error" icon @click="removeQuestion(question)">
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>

export default {
    props: ["editFormData", "question", "index", "txtType"],
    methods: {
        removeQuestion(question) {
            this.editFormData.questions.splice(this.editFormData.questions.indexOf(question), 1)
        }
    }
}
</script>