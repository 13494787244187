<template>
	<v-row class="mt-3">
		<v-col cols="12" class="py-2" v-for="form in forms" :key="form.id">
			<v-card outlined class="pa-3 my-0">
				<span class="d-flex align-center">
					<h4 class="ml-3" v-text="form.title"></h4>
                    <v-spacer></v-spacer>
					<div class="mr-3" v-text=" 'Nombre de questions : ' + form.questions.length"></div>
                    <v-btn icon color="primary" @click="editForm(form)">
                        <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteForm(form)">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
				</span>
			</v-card>
		</v-col>
		<v-col cols="12" class="pa-3 d-flex justify-end">
			<v-btn color="primary" fab @click="openModal">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>
        <!-- MODAL -->
		<v-dialog v-model="dialog" max-width="1000px" persistent>
			<v-card>
				<v-card-title class="pb-0">
					<v-text-field v-model="editFormData.title" label="Titre du formulaire" class="px-2" />
				</v-card-title>
				<v-card-text ref="questionList">
                    <v-row class="pl-5">
                        <v-col cols="12" md="6" class="d-flex align-center justify-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="d-flex align-center">
                                        <v-checkbox v-model="editFormData.rdmOrder.question"></v-checkbox>
                                        <label>Ordre aléatoire des questions</label>
                                    </div>
                                </template>
                                <span>Chaque utilisateur aura un ordre différent.</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex align-center justify-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="d-flex align-center">
                                        <v-checkbox v-model="editFormData.rdmOrder.answer"></v-checkbox>
                                        <label>Ordre aléatoire des réponses</label>
                                    </div>
                                </template>
                                <span>Uniquement disponible pour les questions à choix.</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center py-3 ma-0 warning--text" v-if="editFormData.rdmOrder.question && editFormData.questions.map(q=>q.type).includes('dependency')">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="cursor-default">❗Il est déconseillé d'utiliser des questions dépendantes et un ordre aléatoire.❗</span>
                            </template>
                            <span>Dans le cas d'un énoncé ambigu, les utilisateurs peuvent être induits en erreur.</span>
                        </v-tooltip>
                    </v-row>
                    <span v-for="question, index in editFormData.questions">
                        <v-card outlined class="px-5 pb-0 my-3">
                            <QuestionHeader :editFormData="editFormData" :question="question" :index="index" :txtType="
                                question.type == 'bool' ? 'Vrai/Faux'
                                : question.type == 'number' ? 'Nombre'
                                : question.type == 'dependency' ? 'Nombre dépendant'
                                : question.type == 'text' ? 'Expression'
                                : question.type == 'fillTheBlank' ? 'Texte à trous'
                                : question.type == 'singleChoice' ? 'Choix unique'
                                : question.type == 'multipleChoice' ? 'Choix multiple'
                                : question.type == 'list' ? 'Liste'
                                : question.type == 'orderTheList' ? 'Ranger la liste'
                                : question.type == 'classification' ? 'Classification'
                                : question.type == 'free' ? 'Libre'
                            : ''"></QuestionHeader>

                            <Bool           v-if="question.type == 'bool'"                :profile="profile" :question="question" ref="questions"></Bool>
                            <Number         v-else-if="question.type == 'number'"         :profile="profile" :question="question" ref="questions"></Number>
                            <Dependency     v-else-if="question.type == 'dependency'"     :profile="profile" :question="question" ref="questions" :index="index" :questions="editFormData.questions"></Dependency>
                            <Expression     v-else-if="question.type == 'text'"           :profile="profile" :question="question" ref="questions"></Expression>
                            <FillTheBlank   v-else-if="question.type == 'fillTheBlank'"   :profile="profile" :question="question" ref="questions"></FillTheBlank>
                            <SingleChoice   v-else-if="question.type == 'singleChoice'"   :profile="profile" :question="question" ref="questions"></SingleChoice>
                            <MultipleChoice v-else-if="question.type == 'multipleChoice'" :profile="profile" :question="question" ref="questions"></MultipleChoice>
                            <List           v-else-if="question.type == 'list'"           :profile="profile" :question="question" ref="questions"></List>
                            <OrderTheList   v-else-if="question.type == 'orderTheList'"   :profile="profile" :question="question" ref="questions"></OrderTheList>
                            <Classification v-else-if="question.type == 'classification'" :profile="profile" :question="question" ref="questions"></Classification>
                            <Free           v-else-if="question.type == 'free'"           :profile="profile" :question="question" ref="questions"></Free>
                        </v-card>
                    </span>

                    <v-row>
                        <v-col class="d-flex justify-center align-center mb-5">
                            <div class="d-flex align-center">
                                <v-select v-model="selectedType" :items="questionTypes" item-text="displayName" item-value="value" label="Type de question"></v-select>
                                <v-btn color="primary" class="ml-5" @click="addQuestion" outlined>
                                    <v-icon class="mr-2">mdi-plus</v-icon>
                                    Ajouter une question
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
					<v-btn color="blue darken-1" text @click="save">sauvegarder</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import logger from "@/assets/functions/logger"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Form from "@/classes/Form"

import QuestionHeader from "@/components/Form/QuestionHeader.vue"
import Bool from "@/components/Form/Bool.vue"
import Number from "@/components/Form/Number.vue"
import Dependency from "@/components/Form/Dependency.vue"
import Expression from "@/components/Form/Expression.vue"
import FillTheBlank from "@/components/Form/fillTheBlank.vue"
import SingleChoice from "@/components/Form/SingleChoice.vue"
import MultipleChoice from "@/components/Form/MultipleChoice.vue"
import List from "@/components/Form/List.vue"
import OrderTheList from '@/components/Form/OrderTheList'
import Classification from '@/components/Form/Classification'
import Free from "@/components/Form/Free.vue"

export default {
    name: "FormTab",
    props: ["user", "profile", "notifications", "config", "currentRoom"],
    components: {
        QuestionHeader,
        Bool,
        Number,
        Dependency,
        Expression,
        FillTheBlank,
        SingleChoice,
        MultipleChoice,
        List,
        OrderTheList,
        Classification,
        Free
    },
    data: function () {
        return {
            forms: [],
            dialog: false,
            selectedType: null,
            editFormData: {
                id: null,
                title: null,
                questions: [],
                rdmOrder: {
                    question: false,
                    answer: false
                }
            },
            questionTypes: [
                {
                    displayName: 'Vrai/Faux',
                    value: 'bool',
                },
                {
                    displayName: 'Nombre',
                    value: 'number',
                },
                {
                    displayName: 'Nombre dépendant',
                    value: 'dependency',
                },
                {
                    displayName: 'Expression',
                    value: 'text',
                },
                {
                    displayName: 'Texte à trous',
                    value: 'fillTheBlank',
                },
                {
                    displayName: 'Choix unique',
                    value: 'singleChoice',
                },
                {
                    displayName: 'Choix multiple',
                    value: "multipleChoice",
                },
                {
                    displayName: 'Liste',
                    value: "list",
                },
                {
                    displayName: 'Ranger la liste',
                    value: "orderTheList",
                },
                {
                    displayName: 'Classification',
                    value: "classification",
                },
                {
                    displayName: 'Libre (Sans correction)',
                    value: "free",
                },
            ],
            unsub: [],
        }
    },
    created() {
        this.unsub.push(
            Form.listenByRoom(this.profile.currentRoom, (forms) => {
                let tmp_forms = []
                for (let form of forms) {
                    form.decode()
                    tmp_forms.push(form)
                }
                this.forms = tmp_forms
            })
        )
    },
    methods: {
        openModal() {
            this.editFormData.id = null
            this.editFormData.room = this.profile.currentRoom
            this.editFormData.title = null
            this.editFormData.questions = []
            this.editFormData.rdmOrder = {
                question: false,
                answer: false
            }

            this.selectedType = null

            this.dialog = true
        },
        editForm(form){
            this.editFormData.id = form.id
            this.editFormData.room = this.profile.currentRoom
            this.editFormData.title = form.title
            this.editFormData.questions = form.questions
            this.editFormData.rdmOrder = form.rdmOrder

            this.selectedType = null

            this.dialog = true
        },
        addQuestion() {
            if (this.selectedType) {
                let tmp_question = {
                    type: this.selectedType,
                    text: null,
                    choicesAmount: null,
                    choices: [],
                    correctAnswer: null,
                }

                if(this.selectedType == 'orderTheList'){
                    tmp_question.choices.push('')
                    tmp_question.choices.push('')
                }else if(this.selectedType == 'fillTheBlank'){
                    tmp_question.choices.push({text: '', answer: false})
                    tmp_question.choices.push({text: '', answer: true})
                    tmp_question.choices.push({text: '', answer: false})
                }else if(this.selectedType == 'classification'){
                    tmp_question.choices = {title:'', list:['']}
                    tmp_question.correctAnswer = [
                        {
                            title: '',
                            list: ['']
                        },
                        {
                            title: '',
                            list: ['']
                        }
                    ]
                }

                this.editFormData.questions.push(tmp_question)
            }
        },
        close() {
            this.editFormData.id = null
            this.editFormData.room = this.profile.currentRoom
            this.editFormData.title = null
            this.editFormData.questions = []
            this.editFormData.rdmOrder = {
                question: false,
                answer: false
            }

            this.selectedType = null

            this.dialog = false
        },
        async save(){
            if(!this.editFormData.title){
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez renseigner un titre.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
                return
            }else if(this.editFormData.questions.length == 0){
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez ajouter au moins une question.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
                return
            }
            for(let question of this.$refs.questions){
                let response = question.testIsValid()
                if(!response.valide){
                    Swal.fire({
                        title: 'Erreur',
                        text: response.errorMessage,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    })
                    return
                }
            }
            let isDenied = false
            await Swal.fire({
                title: `Sauvegarder le formulaire ?`,
                text: `Ce formulaire contient ${this.editFormData.questions.length} question${this.editFormData.questions.length > 1 ? 's' : ''}`,
                showCancelButton: true,
                confirmButtonText: 'Sauvegarder',
                cancelButtonText: `Continer de modifier`,
            }).then((result) => {
                // isDismissed = true when cancel button or backdrop is clicked
                if (result.isDismissed) isDenied = true
            })
            if (isDenied) return
            for(let question of this.editFormData.questions){
                if(question.type == 'orderTheList'){
                    question.correctAnswer = question.choices
                }
            }
            if(this.editFormData.id == null){
                let form = new Form(null, this.editFormData.room, this.editFormData.title, this.editFormData.questions, this.editFormData.rdmOrder)
                form.encode()
                form.save()
                .then(()=>{
                    logger.log(this.profile.id, "FORM ADD", this.profile.email + " a ajouté un formulaire")
                    this.close()
                    Swal.fire({
                        title: 'Formulaire Validé',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    })
                })
            }else{
                let form = new Form(this.editFormData.id, this.editFormData.room, this.editFormData.title, this.editFormData.questions, this.editFormData.rdmOrder)
                form.encode()
                Form.updateById(form.id, {
                    room: form.room,
                    title: form.title,
                    questions: form.questions,
                    rdmOrder: form.rdmOrder
                })
                .then(()=>{
                    logger.log(this.profile.id, "FORM UPDATE", this.profile.email + " a modifié le formulaire " + this.editFormData.title)
                    this.close()
                    Swal.fire({
                        title: 'Formulaire Validé',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    })
                })
            }
        },
        deleteForm(form){
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if(result.isConfirmed){
                    form.delete()
                    logger.log(this.profile.id, "FORM DELETED", this.profile.email + " a supprimé le formulaire " + form.title)
                }
            })
        },
    },
    destroyed() {
        for(let unsub of this.unsub){
            unsub()
        }
    },
}
</script>
